import {
	SET_QUESTIONS,
	ADD_QUESTIONS,
	REMOVE_QUESTIONS,
	SAVE_ANSWER,
	REMOVE_ANSWER,
	SET_CATEGORIES,
	SET_CURRENT_ANSWERS
} from '../actions/questionActions';
import {getUserId} from '../../react/utils/appUtils';

const questionReducer = (state = {userAnswers: [], currentAnswers: []}, action) => {
	switch (action.type) {
		case SET_QUESTIONS:
			return {
				...state,
				questions: action.questions
			};
		case ADD_QUESTIONS: {
			let questions = [...state.questions];
			questions.splice(action.questionOrder + 2, 0, ...action.questions);
			return {
				...state,
				questions: questions
			};
		}
		case REMOVE_QUESTIONS: {
			let questions = state.questions.filter((question) => !action.questions.includes(question));
			return {
				...state,
				questions: questions
			};
		}
		case SAVE_ANSWER: {
			const answer = {
				questionId: action.questionId,
				UserId: getUserId(),
				AnswerId: action.answer.id,
				Text: action.answer.text,
				input: action.answer.haveInputText
			};

			let userAnswers = [...state.userAnswers];
			
			if (action.switchAnswer === true) {
				// radio group
				const indexAt = userAnswers.findIndex((userAnswer) => userAnswer.questionId === action.questionId);
				if (indexAt === -1) {
					userAnswers.push(answer);
				} else {
					userAnswers[indexAt] = answer;
				}
			} else {
				// checkbox group
				if (answer.input === 1) {
					// Molimo navesti drugo odgovor
					const indexAt = userAnswers.findIndex((userAnswers) => userAnswers.AnswerId === answer.AnswerId);
					if (indexAt === -1) {
						userAnswers.push(answer);
					} else {
						userAnswers[indexAt] = answer;
					}
				} else {
					userAnswers.push(answer);
				}
			}
			return {
				...state,
				userAnswers: userAnswers
			};
		}
		case SET_CATEGORIES: {
			return {
				...state,
				categories: action.categories
			};
		}
		case REMOVE_ANSWER: {
			let userAnswers = [...state.userAnswers];
			const indexAt = userAnswers.findIndex((answer) => answer.AnswerId === action.answerId);

			if (indexAt !== -1) {
				userAnswers.splice(indexAt, 1);
			}
			return {
				...state,
				userAnswers: userAnswers
			};
		}
		case SET_CURRENT_ANSWERS:
			return {
				...state,
				currentAnswers: action.answers
			};
		default:
			return state;
	}
};

export default questionReducer;
