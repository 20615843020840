import React from 'react';
import LOGO from '../../images/logo-mini.png';
import Routes from '../../utils/routes';
import {NavLink} from 'react-router-dom';
import store from '../../redux/store';
import {openPopup} from '../../redux/actions/modalActions';
import LoginForm from '../components/loginForm';
import {connect} from 'react-redux';
import {isEmpty, isMobile} from '../utils/utils';
import Modal from './modal';
import {removeUserData, redirectTo} from '../utils/appUtils';
import {setCookie, COOCKIE_USER_DATA} from '../utils/coockieUtils';
import {logoutuser} from '../../redux/actions/userActions';
import * as classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Collapse} from 'react-collapse';

const mapStateToProps = ({userData}) => {
	return {userId: userData.userId};
};

class Header extends React.Component {
	// get from props in future or make different array based on logged in user prop

	state = {
		showHeader: !isMobile()
	};

	componentDidMount() {
		this.setState({
			showHeader: !isMobile()
		})
	}

	handleClick = (e, method, disabled) => {
		if (!!method || disabled) {
			e.preventDefault();
			!disabled && method && method();
		}
	};

	openLoginForm = () => {
		store.dispatch(
			openPopup(
				<>
					<LoginForm />
				</>
			)
		);
	};

	logoutUser = () => {
		removeUserData();
		setCookie(COOCKIE_USER_DATA, '');
		store.dispatch(logoutuser());
		redirectTo(Routes.ROOT);
	};

	getMenuRotues = () => {
		return [
			{
				name: 'Pocetna',
				url: Routes.ROOT
			},
			// {
			// 	name: 'Proizvodi',
			// 	url: Routes.PRODUCTS
			// },
			{
				name: 'Partneri',
				url: Routes.PARTNERS
			},
			{
				name: 'Blog',
				url: Routes.BLOG
			},
			{
				name: 'Logout',
				method: this.logoutUser,
				url: Routes.REGISTRATION,
				hidden: isEmpty(this.props.userId)
			},
			{
				name: 'Profil',
				url: Routes.PROFILE,
				hidden: isEmpty(this.props.userId)
			},
			{
				name: 'Login',
				url: Routes.LOGIN,
				method: this.openLoginForm,
				hidden: !isEmpty(this.props.userId)
			},
			{
				name: 'Registracija',
				url: Routes.REGISTRATION,
				hidden: !isEmpty(this.props.userId)
			},
			{
				name: 'English',
				url: Routes.ENGLISH,
				className: 'box-link'
			}
		];
	};

	toggleHeader = () => {
		this.setState({
			showHeader: !this.state.showHeader
		})
	};

	render() {
		const {showHeader} = this.state;
		const headerContentClassName = classnames("header", {'mobile': isMobile()});
		return (
			<div className={headerContentClassName}>
				<Modal />
				<div className="header-content">
					<div className="logo">
						<img src={LOGO} alt="Probaj besplatno" className="logo-image" onClick={() => redirectTo(Routes.ROOT)} />
					</div>
					<span className="burger-menu-icon">
						{!showHeader ? <FontAwesomeIcon icon={faBars} onClick={this.toggleHeader}/>
						 : <FontAwesomeIcon icon={faTimes} onClick={this.toggleHeader}/>}
					</span>
					<Collapse isOpened={showHeader}>
						<div className="menu">
							{this.getMenuRotues().map((route) => (
								<NavLink
									className={classnames(route.hidden ? 'link__hidden' : 'link', {[route.className]: !!route.className})}
									key={route.name}
									to={route.url}
									onClick={(e) => this.handleClick(e, route.method, route.disabled)}>
									{route.name}
								</NavLink>
							))}
						</div>
					</Collapse>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(Header);
