import React, {useRef} from 'react';
import outsideClick from '../utils/useOutsideClick';
import {connect} from 'react-redux';
import {closePopup} from '../../redux/actions/modalActions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const stateToProps = ({modalData}) => {
	return {
		open: modalData.open,
		component: modalData.component,
		customClassName: modalData.customClassName
	}
};

const Modal = (props) => {
	const ref = useRef(null);
	const {component, customClassName} = props;
	const className = classnames('popup__body__wrapper', {[customClassName]: !!customClassName});
	const closeMethod = () => props.dispatch(closePopup());
	outsideClick(ref, closeMethod);
	if (!component) {
		return null;
	}
	return (
		<div className="popup">
			<div ref={ref} className={className}>
				<FontAwesomeIcon className="close" icon={faTimes} onClick={closeMethod} />
				{component}
			</div>
		</div>
	);
};

export default connect(stateToProps)(Modal);
