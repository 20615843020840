import {STORE_CITIES} from '../actions/cityActions';

const cityReducer = (
	state = {
		cities: []
	},
	action
) => {
	switch (action.type) {
		case STORE_CITIES:
			action.cities.sort((a, b) => a.naziv > b.naziv ? 1 : a.naziv < b.naziv ? -1 : 0);
			return {...state, cities: action.cities};
		default:
			return state;
	}
};

export default cityReducer;
