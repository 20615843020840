import React from 'react';
import {Button, Input} from '../basics/components';
import {post} from '../services/service';
import HandleEnterClick from '../utils/handleEnterClick';
import {hideLoader, showLoader} from "../../redux/actions/loaderActions";
import store from "../../redux/store";
import MessageComponent from "../basics/messageComponent";
import {openPopup} from "../../redux/actions/modalActions";
import LoginForm from "./loginForm";

const ERROR_MESSAGE = "Greska prilikom slanja mejla za restartovanje lozinke!";
const SUCCESS_MESSAGE = "U mejlu cete dobiti novu lozinku";

class ForgotPasswordForm extends HandleEnterClick {

    state = {
        email: '',
        showErrorMessage: false,
        showSuccessMessage: false
    };

    onEnterClick = () => {
        this.sendPassword();
    };

    setEmail = (e) => {
        this.setState({
            email: e.target.value,
            showErrorMessage: false,
            showSuccessMessage: false
        });
    };

    sendPassword = () => {
        const {email} = this.state;
        const request = {
            email: email
        };
        store.dispatch(showLoader());
        post('/user/ForgotPass', request, () => {
            store.dispatch(hideLoader());
            this.setState({
                showSuccessMessage: true,
                showErrorMessage: false
            })
        }, () => {
            store.dispatch(hideLoader());
            this.setState({
                showErrorMessage: true,
                showSuccessMessage: false
            });
        });
    };

    backToLogin = () => {
        store.dispatch(
            openPopup(
                <>
                    <LoginForm />
                </>
            )
        );
    };

    render() {
        const {email, showErrorMessage, showSuccessMessage} = this.state;
        return (
            <div className="forgot-password form">
                <div className="login-title">Promena lozinke</div>
                {showErrorMessage && <MessageComponent message={ERROR_MESSAGE} />}
                {showSuccessMessage && <MessageComponent success={true} message={SUCCESS_MESSAGE} />}
                <Input required={false} label="Email" value={email} onChange={this.setEmail} onKeyPress={this.onKeyPress} />
                <div className="buttons-pair">
                    <Button label="RESETUJ" onClick={this.sendPassword} />
                    <Button className="second" label="Ulogujte se" onClick={this.backToLogin} />
                </div>
            </div>
        );
    }
}

export default ForgotPasswordForm;