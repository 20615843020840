import React from 'react';
//eslint-disable-next-line
import './App.scss';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './redux/store';
import Routes from './utils/routes';
import RegistrationPage from './react/pages/registrationPage';
import IframePage from './react/pages/iframePage';
import {storeCities} from './redux/actions/cityActions';
import ProfilePage from './react/pages/profilePage/profilePage';
import {getUserData} from './react/utils/appUtils';
import {setToken, setUserId} from './redux/actions/userActions';
import {isEmpty} from './react/utils/utils';
import PageLoader from './react/basics/pageLoader';
import GoToTopArrow from './react/components/goToTopArrow';
import VerificationPage from './react/pages/verificationPage';
import ConfirmEmailPage from './react/pages/confirmEmail';
import LoginPage from './react/pages/loginPage';

class App extends React.Component {
	componentDidMount() {
		const {userId, token} = getUserData();
		if (!isEmpty(userId) && !isEmpty(token)) {
			store.dispatch(setUserId(userId));
			store.dispatch(setToken(token));
		}
		store.dispatch(storeCities());
	}

	render() {
		return (
			<Provider store={store}>
				<>
					<PageLoader />
					<GoToTopArrow />
					<Router>
						<Switch>
							<Route exact path={Routes.ROOT} component={() => <IframePage page="https://probajbesplatno.apon.us" />} />
							<Route exact path={Routes.PARTNERS} component={() => <IframePage page="https://probajbesplatno.apon.us/partneri" />} />
							<Route exact path={Routes.PRODUCTS} component={() => <IframePage page="https://probajbesplatno.apon.us/nasi-proizvodi" />} />
							<Route exact path={Routes.BLOG} component={() => <IframePage page="https://probajbesplatno.apon.us/blog" />} />
							<Route exact path={Routes.ENGLISH} component={() => <IframePage page="https://probajbesplatno.apon.us/partners" />} />
							<Route exact path={Routes.REGISTRATION} component={RegistrationPage} />
							<Route path={Routes.PROFILE} component={ProfilePage} />
							<Route exact path={Routes.VERIFY} component={VerificationPage} />
							<Route exact path={Routes.CONFIRM} component={ConfirmEmailPage} />
							<Route exact path={Routes.TERMS} component={() => <IframePage page="https://probajbesplatno.apon.us/pravila-i-uslovi-koriscenja/" />} />
							<Route exact path={Routes.PRIVACY} component={() => <IframePage page="https://probajbesplatno.apon.us/politika-privatnosti/" />} />
							<Route path={`${Routes.LOGIN}/:redirectUrl`} component={LoginPage} />
						</Switch>
					</Router>
				</>
			</Provider>
		);
	}
}

export default App;
