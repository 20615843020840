import React from 'react';
import {Image} from 'react-bootstrap';
import {Button} from '../../basics/components';
import {isListEmpty, toCamel} from '../../utils/utils';
import BoxQuestion from './boxQuestion';
import {connect} from 'react-redux';
import {Collapse} from 'react-collapse';
import * as classnames from 'classnames';
import scrollToComponent from 'react-scroll-to-component';
import {post} from '../../services/service';
import {hideLoader, showLoader} from '../../../redux/actions/loaderActions';
import {createErrorPopup} from '../../components/errorPopup';
import store from '../../../redux/store';
import {openPopup} from '../../../redux/actions/modalActions';

const mapStateToProps = ({boxesData, userData}, ownProps) => {
	return {
		answers: boxesData.answers[ownProps.Id] || {},
		userId: userData.userId
	};
};

class BoxSample extends React.Component {
	state = {
		isOpen: false
	};

	fields = {};

	toggle = () => {
		this.props.TrebaOdgovor && this.setState({
			isOpen: !this.state.isOpen
		});
	};

	onSend = () => {
		const {answers, userId, Id, TrebaOdgovor, reloadBoxes} = this.props;
		if (this.isValid() && TrebaOdgovor) {
			const request = Object.values(answers).map((answer) => ({
				ItemQuestionId: answer.questionId,
				ItemAnswerId: answer.answerId,
				UserId: userId,
				Text: answer.text,
				Type: answer.type,
				ImageSource: answer.ImageSource,
				Extenzija: answer.Extenzija,
				ItemId: Id
			}));
			store.dispatch(showLoader());
			post(
				'/boxes/UploadOdgovore/',
				request,
				() => {
					store.dispatch(hideLoader());
					store.dispatch(
						openPopup(
							<div>
								Hvala na popunjenom upitniku, Tvoje mišljenje nam je važno!
							</div>
						)
					);
					reloadBoxes();
				},
				() => {
					store.dispatch(hideLoader());
					createErrorPopup('Greska prilikom slanja odgovora');
				}
			);
		}
	};

	isValid = () => {
		const notValidField = this.getFieldRefs().find((f) => !f.checkValidation().isValid);
		if (!!notValidField) {
			notValidField.handleOnBlur();
			scrollToComponent(notValidField, {offset: 50, duration: 500});
			return false;
		} else {
			return true;
		}
	};

	getFieldRefs = () => {
		return Object.values(this.fields)
			.filter((f) => !!f)
			.map((f) => f.getFieldRefs())
			.flat();
	};

	openImage = (image) => {
		store.dispatch(
			openPopup(
				<>
					<Image className="image-popup" src={image} />
				</>
			)
		);
	};

	render() {
		const {Naziv, Pitanja, Slike, Id, TrebaOdgovor} = this.props;
		const {isOpen} = this.state;
		const showImage = !isListEmpty(Slike);
		const headerClassname = classnames('box-sample__header', {active: isOpen && TrebaOdgovor}, {finished: !TrebaOdgovor});
		return (
			<div className="box-sample">
				<div className={headerClassname}>
					{showImage && 
					<div className="box-sample__header__image-wrapper">
						<Image onClick={() => this.openImage(Slike[0])} src={Slike[0]} />
					</div>
					}
					<div className="box-sample__header__title" onClick={this.toggle}>
						{Naziv}
					</div>
				</div>
				{TrebaOdgovor && (
					<Collapse isOpened={isOpen && TrebaOdgovor}>
						<div className="box-sample__form">
							<div className="box-sample__questions">
								{Pitanja.map((pitanje, index) => (
									<BoxQuestion ref={(ref) => (this.fields[pitanje.Id] = ref)} sampleId={Id} number={index + 1} key={pitanje.Id}
										{...toCamel(pitanje)}
									 />
								))}
							</div>
							<Button isDisabled={!isOpen || !TrebaOdgovor} onClick={this.onSend}>
								{!TrebaOdgovor ? 'Poslato' : 'Pošalji'}
							</Button>
						</div>
					</Collapse>
				)}
			</div>
		);
	}
}

export default connect(mapStateToProps)(BoxSample);
