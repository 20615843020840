import {OPEN_POPUP, CLOSE_POPUP} from '../actions/modalActions';

const popupReducer = (
	state = {
		open: false,
		component: null,
		customClassName: null
	},
	action
) => {
	switch (action.type) {
		case OPEN_POPUP:
			return {...state, open: true, component: action.component, customClassName: action.customClassName};
		case CLOSE_POPUP:
			return {...state, open: false, component: null, customClassName: null};
		default:
			return state;
	}
};

export default popupReducer;
