import React from 'react';
import CollapseDiv from '../../basics/collapseDiv';
import Input from '../../basics/input';
import Button from '../../basics/button';
import {put} from '../../services/service';
import {connect} from 'react-redux';
import {createErrorPopup} from '../errorPopup';
import {hideLoader, showLoader} from '../../../redux/actions/loaderActions';

class ChangePasswordForm extends React.Component {
	state = {
		currentPassword: '',
		newPassword: '',
		repeatedPassword: '',
		isDisabled: true
	};

	fieldRefs = [];

	onChange = (e) => {
		this.setState(
			{
				[e.target.id]: e.target.value
			},
			() => {
				this.checkValidation();
			}
		);
	};

	checkValidation = () => {
		const {newPassword, repeatedPassword} = this.state;
		this.setState({
			isDisabled: !this.isFormValid() || newPassword !== repeatedPassword
		});
	};

	isFormValid = () => {
		return this.fieldRefs
			.filter((f) => !!f)
			.map((f) => f.checkValidation().isValid)
			.every((f) => !!f);
	};

	onClick = () => {
		const {profileData, dispatch} = this.props;
		const id = profileData.Id;
		const request = {
			UserId: id,
			userName: profileData.userName,
			password: this.state.newPassword
		};
		dispatch(showLoader());
		put(
			`/login/${id}`,
			request,
			() => {
				dispatch(hideLoader());
				this.collapseDiv.toggleIsOpen();
			},
			() => {
				dispatch(hideLoader());
				createErrorPopup('Greska pri unosu trenutne sifre');
			}
		);
	};

	render() {
		const {currentPassword, newPassword, repeatedPassword, isDisabled} = this.state;
		let i = 0;
		return (
			<div className="profile-collapse__item">
				<CollapseDiv ref={(ref) => (this.collapseDiv = ref)} title="Lozinka">
					<Input
						ref={(ref) => (this.fieldRefs[i++] = ref)}
						id="currentPassword"
						label="Trenutna lozinka"
						value={currentPassword}
						onChange={this.onChange}
						type="password"
					/>
					<Input
						ref={(ref) => (this.fieldRefs[i++] = ref)}
						id="newPassword"
						label="Nova lozinka"
						value={newPassword}
						onChange={this.onChange}
						type="password"
					/>
					<Input
						ref={(ref) => (this.fieldRefs[i++] = ref)}
						id="repeatedPassword"
						label="Ponovo lozinka"
						value={repeatedPassword}
						onChange={this.onChange}
						type="password"
					/>
					<Button label="Promenite lozinku" onClick={this.onClick} isDisabled={isDisabled} />
				</CollapseDiv>
			</div>
		);
	}
}

const mapStateToProps = ({userData}) => {
	return {
		profileData: userData.profileData,
		credentials: userData.credentials
	};
};

export default connect(mapStateToProps)(ChangePasswordForm);
