import {get, post} from '../../react/services/service';
import {showLoader, hideLoader} from './loaderActions';
import {calculateAnsweredCategories} from '../../react/utils/appUtils';

export const SET_QUESTIONS = 'SET_QUESTIONS';
export const ADD_QUESTIONS = 'ADD_QUESTIONS';
export const REMOVE_QUESTIONS = 'REMOVE_QUESTIONS';
export const SAVE_ANSWER = 'SAVE_ANSWER';
export const REMOVE_ANSWER = 'REMOVE_ANSWER';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CURRENT_ANSWERS = 'SET_CURRENT_ANSWERS';

export const getQuestions = (userId, categories) => {
	return (dispatch) => {
		dispatch(showLoader());
		get('/Questions', (response) => {
			dispatch(setQuestions(response));
			dispatch(hideLoader());
		});
	};
};

export const saveCurrentAnswer = (answers) => ({
	type: SET_CURRENT_ANSWERS,
	answers
});

export const getCurrentUserAnswers = (userId, token) => {
	const request = {
		"UserId": userId,
		"token": token
	};
	return (dispatch) => {
		post(
			'/UserAnswers/Odgovori', request,
			(answers) => {
				const categories = calculateAnsweredCategories(answers);
				dispatch(saveCurrentAnswer(answers));
				dispatch(getQuestions(userId, categories));
				dispatch(setCategories(categories));
			},
			() => {
				dispatch(getQuestions(userId, []));
				dispatch(setCategories(calculateAnsweredCategories([])));
			}
		);
	};
};

export const setQuestions = (questions) => ({
	type: SET_QUESTIONS,
	questions
});

export const addQuestions = (questionOrder, questions) => ({
	type: ADD_QUESTIONS,
	questionOrder,
	questions
});

export const removeQuestions = (questions) => ({
	type: REMOVE_QUESTIONS,
	questions
});

export const saveAnswer = (questionId, answer, switchAnswer = false) => ({
	type: SAVE_ANSWER,
	questionId,
	answer,
	switchAnswer
});

export const setCategories = (categories) => ({
	type: SET_CATEGORIES,
	categories
});

export const removeAnswer = (answerId) => ({
	type: REMOVE_ANSWER,
	answerId
});
