import React from 'react';
import {Col as ReactCol} from 'react-bootstrap';
import classnames from 'classnames';

export default class Col extends React.Component {
	render() {
		const className = classnames({'no-padding': this.props.noPadding}, {[this.props.className]: !!this.props.className});
		return (
			<ReactCol
				onClick={this.props.onClick}
				xs={this.props.xs}
				md={this.props.md}
				sm={this.props.sm}
				lg={this.props.lg}
				className={className}
				style={this.props.style}>
				{this.props.children}
			</ReactCol>
		);
	}
}
