import {isEmpty} from './utils';

export const COOCKIE_USER_DATA = 'USER_DATA';

export const setCookie = (name, value, daysToLive = 1) => {
	let cookie = name + '=' + encodeURIComponent(value);
	cookie += '; max-age=' + daysToLive * 24 * 60 * 60;
	document.cookie = cookie;
};

export const getCookie = (name) => {
	return document.cookie
		.split(';')
		.map((cookie) => {
			const cookiePair = cookie.split('=');
			if (name === cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
			return null;
		})
		.find((c) => !!c);
};

export const checkCookie = (name) => {
	return !isEmpty(getCookie(name));
};
