import {combineReducers} from 'redux';
import cityReducer from './cityReducer';
import userReducer from './userReducer';
import modalReducer from './modalReducer';
import questionReducer from './questionReducer';
import loaderReducer from './loaderReducer';
import consentReducer from "./consentReducer";
import boxesReducer from "./boxesReducer";

export default combineReducers({
	cityData: cityReducer,
	userData: userReducer,
	modalData: modalReducer,
	questionData: questionReducer,
	loaderData: loaderReducer,
	consentData: consentReducer,
	boxesData: boxesReducer
});
