import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import {isEmpty} from '../utils/utils';

class Input extends Component {
	state = {
		focus: false,
		isValid: false,
		validationChecked: false
	};

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.props.receiveProps && nextProps.validationChecked !== this.state.validationChecked) {
			this.handleOnBlur();
		}
	}

	handleFocus = () => {
		this.setState({focus: !this.state.focus});
	};

	handleOnBlur = () => {
		this.handleFocus();
		const validationObject = this.checkValidation();
		this.setState({
			isValid: validationObject.isValid,
			errorMessage: validationObject.message,
			validationChecked: true
		});
		this.props.onBlur && this.props.onBlur();
	};

	checkValidation = () => {
		if (this.props.required && isEmpty(this.props.value)) {
			return {
				isValid: false,
				message: 'Ovo polje je obavezno'
			};
		}
		return this.props.checkValidation ? this.props.checkValidation() : {isValid: true};
	};

	render() {
		const {iconName, label, placeholder, id, value, onChange, type, hidden, disabled} = this.props;
		const {validationChecked, isValid, focus, errorMessage} = this.state;
		const className = classnames('input-wrapper', {checkbox: type === 'checkbox'}, {focus: focus}, {'not-visible': hidden});
		const inputClassName = classnames({error: !isValid && validationChecked}, {correct: validationChecked && isValid});
		return (
			<div className={className}>
				{label && <label className="input-label">{label}</label>}
				{iconName && (
					<div className="icon">
						<FontAwesomeIcon icon={iconName} />
					</div>
				)}
				<div className="input-field">
					<input
						className={inputClassName}
						placeholder={placeholder}
						id={id}
						value={value}
						onChange={onChange}
						type={type}
						disabled={disabled}
						onFocus={this.handleFocus}
						onBlur={this.handleOnBlur}
					/>
					{!isValid && validationChecked && <span className="error-message">{errorMessage}</span>}
				</div>
			</div>
		);
	}
}

Input.defaultProps = {
	required: true,
	isValid: true,
	receiveProps: true,
	checkValidation: () => {
		return {isValid: true, message: ''};
	},
	onChange: () => {},
	hidden: false,
	disabled: false
};

export default Input;
