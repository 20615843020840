import React from 'react';
import classnames from 'classnames';

const MessageComponent = (props) => {
	const messageClassName = classnames('message-div', {success: !!props.success});
	const messagePrefix = !!props.success ? 'Info: ' : 'Greska: ';
	return (
		<div className={messageClassName}>
			<span className="message-div__prefix">{messagePrefix}</span>
			<span className="message-div__message">{props.message}</span>
		</div>
	);
};

MessageComponent.defaultProps = {
	success: false
};

export default MessageComponent;
