import React from 'react';
import Header from '../components/header';
import '../../scss/verification.scss';

export default class ConfirmEmailPage extends React.Component {

    render() {
        return (
            <>
                <Header />
                <div className="confirm-mail-container">
                    <h2 className="text">Email je poslat na tvoju adresu. Potvrdi adresu klikom na link u mejlu.</h2>
                </div>
            </>
        )
    }

}