import React from 'react';
import RadioGroup from '../../basics/radio/radioGroup';
import CheckboxGroup from '../../basics/checkbox/checkboxGroup';
import {isEmpty, isListEmpty} from '../../utils/utils';
import {TextArea} from '../../basics/components';
import ImageInput from '../../basics/imageInput/imageInput';
import store from '../../../redux/store';
import {removeBoxAnswer, setBoxAnswer} from '../../../redux/actions/boxesActions';

class BoxQuestion extends React.Component {
	state = {
		subQuestions: {},
		currentAnswer: '',
		file: null,
		text: null,
		answerId: null,
		type: null
	};

	fieldRef = null;
	childRefs = {};

	AnswerComponent = () => {
		const {odgovori, id, maxAnswer} = this.props;
		const {currentAnswer} = this.state;
		
		switch (odgovori[0].type) {
			case 0:
				return maxAnswer < 2 ? (
					<RadioGroup
						ref={(ref) => (this.fieldRef = ref)}
						onChange={this.handleRadioChange}
						items={odgovori}
						selectedIndex={odgovori.findIndex((o) => o.id === currentAnswer)}
						id={id}
					/>
				) : (
					<CheckboxGroup
						ref={(ref) => (this.fieldRef = ref)}
						items={odgovori}
						required={true}
						numberOfAnswers={maxAnswer}
						id={id}
						onChange={this.handleCheckboxChange}
						selected={currentAnswer
							.split(',')
							.filter((k) => k !== '')
							.map((k) => parseInt(k))}
					/>
				);
			case 1:
				return <TextArea ref={(ref) => (this.fieldRef = ref)} value={currentAnswer} onChange={(e) => this.onInputChange(e, odgovori[0])} />;
			case 2:
				return (
					<ImageInput
						ref={(ref) => (this.fieldRef = ref)}
						onChange={(fileObject) => this.handleImageChange(fileObject, odgovori[0])}
						label="Dodajte ili prevucite sliku..."
					/>
				);
			default:
				return null;
		}
	};

	getTextValue = () => {
		const {odgovori, maxAnswer} = this.props;
		const {currentAnswer} = this.state;
		if (!isEmpty(currentAnswer)) {
			switch (odgovori[0].type) {
				case 0:
					return maxAnswer < 2
						? odgovori.find((o) => o.id === currentAnswer).text
						: currentAnswer
								.split(',')
								.filter((k) => k !== '')
								.map((k) => parseInt(k))
								.map((index) => odgovori[index].text)
								.join(',');
				case 1:
					return currentAnswer;
				default:
					return null;
			}
		}
		return null;
	};

	setAnswerData = () => {
		const {id, sampleId} = this.props;
		const {file, text, answerId, type} = this.state;
		const data = {
			type: type,
			answerId: answerId,
			sampleId: sampleId,
			questionId: id,
			text: text || this.getTextValue(),
			ImageSource: file && file.byte,
			Extenzija: file && file.extension
		};
		store.dispatch(setBoxAnswer(sampleId, id, data));
	};

	/**
	 * Rekurzivno brisanje odgovora za sub-pitanja
	 */
	removeQuestionAnswers = (odgovor) => {
		const {sampleId} = this.props;
		const subQuestions = odgovor.pitanja || [];
		if (subQuestions.length > 0) {
			subQuestions.forEach((q) => {
				store.dispatch(removeBoxAnswer(sampleId, q.id));
				q.odgovori
					.filter((o) => o.hasChild)
					.forEach((o) => {
						this.removeQuestionAnswers(o);
					});
			});
		}
	};

	handleImageChange = (fileObject, answerData) => {
		this.setState(
			{
				file: fileObject,
				type: answerData.type,
				answerId: answerData.id
			},
			this.setAnswerData
		);
	};

	onInputChange = (e, answerData) => {
		const value = e.target.value;
		this.setState(
			{
				currentAnswer: value,
				text: value,
				type: answerData.type,
				answerId: answerData.id
			},
			this.setAnswerData
		);
	};

	handleRadioChange = (questionOrder, odgovor, oldOdgovor) => {
		if (this.state.currentAnswer !== odgovor.id) {
			this.setState(
				{
					currentAnswer: odgovor.id,
					answerId: odgovor.id,
					type: odgovor.type,
					text: odgovor.text
				},
				() => {
					this.handleSubQuestion(odgovor, oldOdgovor);
					oldOdgovor && this.removeQuestionAnswers(oldOdgovor);
				}
			);
		}
	};

	handleCheckboxChange = (questionOrder, answer, added) => {
		const {currentAnswer} = this.state;
		const {odgovori} = this.props;
		const answerIndex = odgovori.findIndex((o) => o.id === answer.id);
		if (added) {
			const firstAnswer = currentAnswer === '';
			const stringToAdd = firstAnswer ? answerIndex : ',' + answerIndex;
			this.setState({
				currentAnswer: currentAnswer.concat(stringToAdd),
				answerId: answer.id,
				type: answer.type,
				text: answer.text
			});
		} else {
			const lastInCurrent = currentAnswer.indexOf(answerIndex) === currentAnswer.length - 1;
			const stringToReplace = lastInCurrent ? answerIndex : answerIndex + ',';
			this.setState({
				currentAnswer: currentAnswer.replace(stringToReplace, ''),
				answerId: answer.id,
				type: answer.type,
				text: answer.text
			});
		}
		this.handleSubQuestion(answer, null, !added);
	};

	handleSubQuestion = (odgovor, oldOdgovor, forceDelete = false) => {
		let {subQuestions} = this.state;
		if (odgovor.hasChild && !forceDelete) {
			subQuestions[odgovor.id] = odgovor.pitanja;
		} else {
			this.removeQuestionAnswers(odgovor);
			delete subQuestions[odgovor.id];
		}
		if (!!oldOdgovor) {
			this.removeQuestionAnswers(odgovor);
			delete subQuestions[oldOdgovor.id];
		}
		this.setState(
			{
				subQuestions: subQuestions
			},
			this.setAnswerData
		);
	};

	getFieldRefs = () => {
		const childRefs = Object.values(this.childRefs)
			.filter((f) => !!f)
			.map((f) => {
				return f.getFieldRefs().flat();
			});
		return [this.fieldRef, childRefs.flat()].flat();
	};

	render() {
		const {text, number, sampleId} = this.props;
		let {subQuestions} = this.state;
		subQuestions = Object.values(subQuestions).flat();
		return (
			<div className="question">
				<span className="question__text">
					{number}. {text}
				</span>
				<div className="answer">{this.AnswerComponent()} </div>
				{!isListEmpty(subQuestions) &&
					subQuestions.map((pitanje, index) => (
						<BoxQuestion
							ref={(ref) => (this.childRefs[pitanje.id] = ref)}
							sampleId={sampleId}
							number={`${number}.${index + 1}`}
							key={pitanje.id}
							{...pitanje}
						/>
					))}
			</div>
		);
	}
}

export default BoxQuestion;
