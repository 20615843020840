import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Scrollable from "./Scrollable";
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';

class GoToTopArrow extends Scrollable {
	state = {
		belowHalfHeight: false
	};

	onArrowClick = () => {
		this.triggerScroll();
	};

	render() {
		const {belowHalfHeight} = this.state;
		const arrowClassName = classnames('to-top-arrow', {visible: belowHalfHeight});
		return (
			<div className={arrowClassName}>
				<FontAwesomeIcon className="arrow-up" icon={faArrowAltCircleUp} onClick={this.onArrowClick} />
			</div>
		);
	}
}

export default GoToTopArrow;
