import {SHOW_LOADER, HIDE_LOADER} from '../actions/loaderActions';

const loaderReducer = (
	state = {
		visible: false
	},
	action
) => {
	switch (action.type) {
		case SHOW_LOADER:
			return {...state, visible: true};
		case HIDE_LOADER:
			return {...state, visible: false};
		default:
			return state;
	}
};

export default loaderReducer;
