import React from 'react';
import Header from '../components/header';
import '../../scss/home.scss';

export default class IframePage extends React.Component {
	render() {
		return (
			<>
				<Header />
				<div className="home-container">
					<iframe title="home-page" src={this.props.page} />
				</div>
			</>
		);
	}
}
