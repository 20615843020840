import React from 'react';
import CollapseDiv from '../../basics/collapseDiv';
import Input from '../../basics/input';
import {connect} from 'react-redux';
import {post} from '../../services/service';
import NavLinkButton from '../../basics/NavLinkButton';
import Routes from '../../../utils/routes';
import {createErrorPopup} from '../errorPopup';
import {removeUserData} from '../../utils/appUtils';
import {logoutuser} from '../../../redux/actions/userActions';
import {setCookie, COOCKIE_USER_DATA} from '../../utils/coockieUtils';

class RemoveAccountForm extends React.Component {
	state = {
		password: ''
	};

	onClick = (redirectCallback) => {
		const {userId, userName, dispatch} = this.props;
		const {password} = this.state;
		const request = {
			id: userId,
			username: userName,
			pass: password
		};
		post(
			`/user/DeleteThisUser`,
			request,
			() => {
				removeUserData();
				setCookie(COOCKIE_USER_DATA, '');
				dispatch(logoutuser());
				redirectCallback();
			},
			this.handleDeleteError
		);
	};

	handleDeleteError = () => {
		createErrorPopup('Greska prilikom brisanja profile, proverite sifru koju ste ukucali');
	};

	render() {
		return (
			<div className="profile-collapse__item">
				<CollapseDiv title="Obrisi nalog">
					<div className="remove-paragraph">
						Da ali ste sigurni da želite da obrišete nalog? Ova opcija će obrisati sve Vaše podatke, kao i progres koji ste ostvarili.
					</div>
					<Input type="password" label="Lozinka" onChange={(e) => this.setState({password: e.target.value})} value={this.state.password} />
					<NavLinkButton
						className="btn"
						label="Obrisi"
						beforeChange={this.onClick}
						isDisabled={this.state.password === ''}
						to={Routes.REGISTRATION}
					/>
				</CollapseDiv>
			</div>
		);
	}
}

const mapStateToProps = ({userData}) => {
	return {
		userName: userData.profileData.userName,
		userId: userData.userId
	};
};

export default connect(mapStateToProps)(RemoveAccountForm);
