import React from 'react';
import RadioGroup from '../../basics/radio/radioGroup';
import store from '../../../redux/store';
import {addQuestions, getCurrentUserAnswers, removeAnswer, removeQuestions, saveAnswer, setQuestions} from '../../../redux/actions/questionActions';
import {connect} from 'react-redux';
import CheckboxGroup from '../../basics/checkbox/checkboxGroup';
import Button from '../../basics/button';
import {isNull} from '../../utils/utils';
import {hideLoader, showLoader} from '../../../redux/actions/loaderActions';
import classnames from 'classnames';
import PullInput from '../../basics/pullInput';
import ConsentCheck from '../../basics/checkbox/consentCheck';
import scrollToComponent from 'react-scroll-to-component';
import {createErrorPopup} from '../errorPopup';
import Scrollable from '../Scrollable';
import {post} from '../../services/service';
import TableField from '../../basics/table/tableField';
import {getKidQuestion, hideFirstQuestions} from '../../utils/appUtils';
import {NavLink} from 'react-router-dom';
import Routes from '../../../utils/routes';
import KidQuestion from '../../basics/kid/kidQuestion';
import {hideKidsConsent, showKidsConsent, setShowAdditionalQuestions} from "../../../redux/actions/consentActions";
import ProfileDetails from './profileDetails';

export const IMAGE_QUESTION_ID = 6;
export const KIDS_ANSWER_ID = 41;

export const PARENT_QUESTION_TITLE = {
	7: 'Kozmetika i nega',
	30: 'Hrana i pice',
	73: 'Dom i life style',
	111: 'Bebe i deca'
};

export const IMAGE_ANSWERS = [38, 39, 40, 41];

export const TABLE_ANSWERS = [94, 95, 96, 97, 98];

export const FUTURE_DATES_ANSWER_IDS = [130, 131];

const Question = (props) => {
	const titleClassName = classnames('profile-poll__question__title', {
		'big-center-text': props.id === props.questionWithoutAnswer,
		'title-background': props.id === IMAGE_QUESTION_ID
	});
	const profilePullClassName = classnames('profile-poll__question', {'table-field': props.isTable});
	return (
		<div className={profilePullClassName}>
			{!!PARENT_QUESTION_TITLE[props.id] && <div className="profile-poll__question__parent-title">{PARENT_QUESTION_TITLE[props.id]}</div>}
			{!props.isTable && <div className={titleClassName}>{props.title}</div>}
			{/* TODO add small text to indicate number of answers*/}
			{props.isRadio ? (
				props.isTable ? (
					<TableField {...props} ref={(ref) => props.setRef(props.id, ref)} />
				) : (
					<RadioGroup {...props} ref={(ref) => props.setRef(props.id, ref)} useStoredIndex={true} />
				)
			) : props.isSimpleField ? (
				<PullInput required={true} {...props} ref={(ref) => props.setRef(props.id, ref)} />
			) : props.isKidQuestion ? (
				<KidQuestion {...props} ref={(ref) => props.setRef(props.id, ref)} />
			) : (
				<CheckboxGroup useStoredIndex={true} required={true} {...props} ref={(ref) => props.setRef(props.id, ref)} />
			)}
		</div>
	);
};

const mapStateToProps = ({questionData, userData, consentData}) => ({
	questions: questionData.questions,
	userId: (userData.profileData && userData.profileData.Id) || null,
	token: userData.token,
	userAnswers: questionData.userAnswers,
	categories: questionData.categories,
	disableForm: userData.profileData && userData.profileData.Status === 0,
	currentAnswers: questionData.currentAnswers,
	showKidsConsent: !!consentData.visible,
	showAdditionalQuestions: consentData.showAdditionalQuestions
});

class ProfilePoll extends Scrollable {
	state = {
		isKidsQuestionSelected: false
	};
	refs = {};

	componentDidMount() {
		const {questions, userId, token} = this.props;
		if (isNull(questions)) {
			store.dispatch(getCurrentUserAnswers(userId, token));
		} else {
			store.dispatch(hideLoader());
		}
	}

	/** Checkbox/Image click handler */
	checkboxAnswerSelected = (questionOrder, answer, added, questionId) => {
		if (added) {
			this.saveAnswer(answer, questionId);
		} else {
			this.removeAnswer(answer.id);
		}
		if (answer.subQuestion.length > 0) {
			if (this.isImage(answer)) {
				this.handleImageClicked(added, answer);
			} else {
				if (added) {
					store.dispatch(addQuestions(questionOrder, answer.subQuestion));
				} else {
					this.removeQuestions(answer.subQuestion);
				}
			}
		}
	};

	removeAnswer = (answerId) => {
		store.dispatch(removeAnswer(answerId));
	};

	handleImageClicked = (added, {subQuestion, id}) => {
		if (id === KIDS_ANSWER_ID) {
			subQuestion.push(getKidQuestion());
			store.dispatch(added ? showKidsConsent() : hideKidsConsent())
		}
		this.setState({
			[id]: added ? subQuestion : null
		});
	};

	isImage = (answer) => IMAGE_ANSWERS.includes(answer.id);

	removeQuestions = (subQuestion) => {
		if (subQuestion.length > 0) {
			store.dispatch(removeQuestions(subQuestion));
			subQuestion.forEach(({answers}) => answers.forEach(({subQuestion}) => this.removeQuestions(subQuestion)));
		}
	};

	sendAnswers = () => {
		const {dispatch, userId, token, userAnswers, currentAnswers} = this.props;
		const request = {
			"UserId": userId,
			"Odgovori": [...currentAnswers, ...userAnswers],
			"token": token
		};
		this.sendKidAnswers();
		dispatch(showLoader());
		post('/UserAnswers/UcitajOdgovore', request, () => {
			dispatch(hideLoader());
			window.location.reload();
		}, () => {
			dispatch(hideLoader());
		});
	};

	sendKidAnswers = () => {
		const {userId} = this.props;
		const kidField = this.refs[getKidQuestion().id];
		if (!isNull(kidField)) {
			const request = kidField.getValue().map((f) => ({UserId: userId, ...f}));
			post(`/UserKids/${userId}`, request);
		}
	};

	radioAnswerSelected = (questionOrder, answer, oldAnswer, questionId) => {
		oldAnswer && this.removeAnswer(oldAnswer.id);
		this.saveAnswer(answer, questionId, true);
		if (oldAnswer && oldAnswer.subQuestion.length > 0) {
			this.removeQuestions(oldAnswer.subQuestion);
		}
		if (answer.subQuestion.length > 0) {
			store.dispatch(addQuestions(questionOrder, answer.subQuestion));
		}
	};

	inputFieldChange = (answer, questionId, answerId) => {
		store.dispatch(saveAnswer(questionId, {id: answerId, text: answer, haveInputText: 1}));
	};

	saveAnswer = (answer, questionId, switchAnswer) => {
		store.dispatch(saveAnswer(questionId, answer, switchAnswer));
	};

	setRef = (questionId, ref) => {
		this.refs = {
			...this.refs,
			[questionId]: ref
		};
		Object.keys(this.refs).forEach((key) => this.refs[key] == null && delete this.refs[key]);
	};

	getRefArray = () => {
		return Object.keys(this.refs).map((k) => this.refs[k]);
	};

	isSimpleField = ({maxAnswer, answers}) => {
		return maxAnswer === -1 || (maxAnswer === 1 && answers.every((a) => a.text === ''));
	};

	questionWithoutAnswer = (question) => {
		return question.maxAnswer <= 1 && question.answers.length === 0;
	};

	kidsQuestionDone = () => {
		return this.props.categories[KIDS_ANSWER_ID] === true;
	};

	allCategoriesDone = () => {
		return Object.values(this.props.categories).every((k) => k === true);
	};

	addAdditionalQuestions = () => {
		const {dispatch, questions} = this.props;
		const additionalQuestions = IMAGE_ANSWERS.filter((ia) => !!this.state[ia]).flatMap((ia) => this.state[ia]);
		const categoryQuestion = questions && questions.find(q => q.id === IMAGE_QUESTION_ID);
		if (additionalQuestions.length > 0) {
			dispatch(setQuestions([categoryQuestion, ...additionalQuestions]));
			return true;
		}
		return false;
	};

	onPopuni = () => {
		this.submit();
		setTimeout(() => scrollToComponent(this.questionsRef, {offset: 0, duration: 500, align: 'top'}), 300);
	}

	submit = () => {
		const {dispatch, showAdditionalQuestions} = this.props;
		if (!showAdditionalQuestions) {
			if (this.isValid()) {
				if (!this.checkConsents()) {
					createErrorPopup('Morate da potvrdite saglasnost pre nego posaljete!');
				} else {
					if (this.addAdditionalQuestions()) {
						dispatch(setShowAdditionalQuestions(true));
					}
				}
			} else {
				this.scrollToNotValidField();
			}
		} else {
			if (this.isValid()) {
				this.sendAnswers();
			} else {
				this.scrollToNotValidField();
			}
		}
	};

	checkConsents = () => {
		return (isNull(this.consent) ? true : this.consent.isChecked()) && (isNull(this.kidsConsent) ? true : this.kidsConsent.isChecked());
	};

	scrollToNotValidField = () => {
		const notValid = (field) => field.checkValidation && field.checkValidation().isValid === false;
		const order = (field) => field.props.questionOrder;
		const notValidField = this.getRefArray().sort((a, b) => order(a) - order(b)).find(notValid);
		if (notValidField) {
			notValidField.setState(
				{
					validationChecked: true
				},
				() => scrollToComponent(notValidField, {offset: 50, duration: 500})
			);
		}
	};

	isValid = () => {
		const isValid = (field) => field.checkValidation && field.checkValidation().isValid === true;
		return Object.keys(this.refs).every((key) => {
			return isValid(this.refs[key]);
		});
	};

	getQuestionsComponents = (questions) => {
		return questions.map((question, index) => {
			const isSimpleField = this.isSimpleField(question);
			const questionWithoutAnswer = this.questionWithoutAnswer(question);
			const isRadio = question.maxAnswer === 1 && !isSimpleField && !questionWithoutAnswer;
			const isTable = TABLE_ANSWERS.includes(question.id);
			const showAnswersInTable = TABLE_ANSWERS[0] === question.id;
			const id = question.id;
			const isKidQuestion = !!question.kidQuestion;
			const questionProps = {
				numberOfAnswers: question.maxAnswer,
				id: id,
				items: question.answers,
				title: question.text,
				onChange: isRadio ? this.radioAnswerSelected : isSimpleField ? this.inputFieldChange : this.checkboxAnswerSelected,
				questionOrder: index,
				isTable: isTable,
				showAnswersInTable: showAnswersInTable,
				isRadio: isRadio,
				isSimpleField: isSimpleField,
				questionWithoutAnswer: questionWithoutAnswer,
				setRef: this.setRef,
				categories: this.props.categories,
				isKidQuestion: isKidQuestion
			};
			return <Question key={id} {...questionProps} />;
		});
	};

	areAllCategoriesChecked = () => {
		const {userAnswers} = this.props;
		const answerIds = userAnswers.map((ua) => ua.AnswerId);
		return IMAGE_ANSWERS.every((ia) => answerIds.includes(ia));
	};

	render() {
		const {questions, categories, disableForm, showKidsConsent, showAdditionalQuestions} = this.props;
		const showConsents = !showAdditionalQuestions;
		const questionLoaded = !!questions && !!categories;
		const className = classnames('profile-poll', {'disable-form': disableForm});
		const categoriesClassName = classnames("profile-poll-categories", {'disable-form': disableForm || showAdditionalQuestions})
		const showMessage =
			questionLoaded && !showAdditionalQuestions && !hideFirstQuestions(categories) && !this.allCategoriesDone() && !this.areAllCategoriesChecked();
		const categoryQuestion = questionLoaded && questions.find(q => q.id === IMAGE_QUESTION_ID);
		const notCategoryQuestions = questionLoaded && questions.filter(q => q.id !== IMAGE_QUESTION_ID);

		return (
			<>
				<div className="profile-details-wrapper">
					<ProfileDetails className="profile-details" />
					<div className={categoriesClassName}>
						{questionLoaded && this.getQuestionsComponents([categoryQuestion])}
						{showConsents && !hideFirstQuestions(categories) && (
								<ConsentCheck
									ref={(ref) => (this.consent = ref)}
									text={
										<span>
											Saglasan sam sa <NavLink to={Routes.TERMS} target="_blank">Probaj Besplatno Pravilima i uslovima korišćenja</NavLink> i{' '}
											<NavLink to={Routes.PRIVACY} target="_blank">Politikom privatnosti</NavLink>
										</span>
									}
								/>
							)}
						{showConsents && showKidsConsent && !this.kidsQuestionDone() && (
							<ConsentCheck
								ref={(ref) => (this.kidsConsent = ref)}
								text="Potvrdjujem da sam roditelj deteta mladjeg od 12 godina i saglasan/saglasna sam da primam proizvode/uzorke ove kategorije."
							/>
						)}
						{showMessage && <span className="category-info">* U bilo kom trenutku možeš izabrati dodatnu kategoriju interesovanja"</span>}
						{questionLoaded && !this.allCategoriesDone() && !showAdditionalQuestions && <Button className="submit-button" onClick={this.onPopuni} label="Pošalji odgovore" />}
					</div>
				</div>
				{disableForm && (
					<div className="verification">
						<span>Morate prvo da verifikujete nalog mejlom da biste odgovorili na pitanja!</span>
					</div>
				)}
				<div className={className} ref={ref => this.questionsRef = ref}>
					{questionLoaded && !showAdditionalQuestions && !hideFirstQuestions(categories) && this.getQuestionsComponents(notCategoryQuestions)}
					{questionLoaded && (
						<>
							{showAdditionalQuestions && this.getQuestionsComponents(notCategoryQuestions)}
							
							{questionLoaded && !this.allCategoriesDone() && showAdditionalQuestions && <Button className="submit-button" onClick={this.submit} label="Pošalji odgovore" />}
						</>
					)}
				</div>
			</>
		);
	}
}

export default connect(mapStateToProps)(ProfilePoll);
