import {get, post} from '../../react/services/service';
import {hideLoader, showLoader} from "./loaderActions";

export const GET_ALL_BOXES = 'GET_ALL_BOXES';
export const GET_HAS_USER_NEW_ITEMS = 'GET_HAS_USER_NEW_ITEMS';
export const SET_BOX_ANSWER = 'SET_BOX_ANSWER';
export const REMOVE_BOX_ANSWER = 'REMOVE_BOX_ANSWER';
export const REMOVE_ALL_ANSWERS = 'REMOVE_ALL_ANSWERS';

export const getBoxes = (userId, token) => {
    const request = {
        "UserId": userId,
        "token": token
    };
    return (dispatch) => {
        dispatch(showLoader());
        post('/boxes/Kutije', request,
            (response) => {
                dispatch(setBoxes(response.kutije));
                dispatch(hideLoader());
            },
            () => {
                dispatch(hideLoader());
            }
        );
    };
};

export const hasUserNewItems = (userId) => {
    return (dispatch) => {
        dispatch(showLoader());
        get(`/user/HasUserNewItems/${userId}`,
            (response) => {
                dispatch(setBoxes(response));
                dispatch(hideLoader());
            },
            () => {
                dispatch(hideLoader());
            }
        );
    };
};

export const removeAllAnswers = () => ({
    type: REMOVE_ALL_ANSWERS
});

export const setBoxAnswer = (boxId, questionId, answerData) => ({
    type: SET_BOX_ANSWER,
    boxId,
    questionId,
    answerData
});

export const removeBoxAnswer = (boxId, questionId) => ({
    type: REMOVE_BOX_ANSWER,
    boxId,
    questionId
});


export const setBoxes = (boxes) => ({
    type: GET_ALL_BOXES,
    boxes
});

export const setUserHasItems = (hasNewItems) => ({
    type: GET_HAS_USER_NEW_ITEMS,
    hasNewItems
});

