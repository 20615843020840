import {
    GET_ALL_BOXES,
    GET_HAS_USER_NEW_ITEMS,
    REMOVE_ALL_ANSWERS,
    REMOVE_BOX_ANSWER,
    SET_BOX_ANSWER
} from "../actions/boxesActions";

const boxesReducer = (
    state = {
        boxes: null,
        loaded: false,
        hasNewItems: null,
        answers: {}
    },
    action
) => {
    const newAnswers = state.answers;
    switch(action.type) {
        case GET_ALL_BOXES:
            return {...state, boxes: action.boxes || []};
        case GET_HAS_USER_NEW_ITEMS:
            return {...state, hasNewItems: action.hasNewItems};
        case SET_BOX_ANSWER:
            const {answerData, questionId, boxId} = action;
            newAnswers[boxId]= state.answers[boxId] || {};
            newAnswers[boxId][questionId] = answerData;
            return {...state, answers: newAnswers};
        case REMOVE_BOX_ANSWER:
            if (!!newAnswers[action.boxId] && !!newAnswers[action.boxId][action.questionId]) {
                delete newAnswers[action.boxId][action.questionId];
            }
            return {...state, answers: newAnswers};
        case REMOVE_ALL_ANSWERS:
            return {...state, answers: {}}
        default:
            return state;
    }
};

export default boxesReducer;
