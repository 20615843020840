import {isEmpty} from "./utils";


export const formatDate = (date) => {
    if (!isEmpty(date)) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return day + '/' + month + '/' + year;
    }
    return null;
};

export const formatToIsoString = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
};