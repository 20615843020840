import React, {Component} from 'react';
import classnames from 'classnames';
import {Collapse} from 'react-collapse';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

class CollapseDiv extends Component {
	state = {
		isOpen: !!this.props.isOpen
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.isOpen !== prevProps.isOpen) {
			this.setState({
				isOpen: this.props.isOpen
			});
		}
	}

	toggleIsOpen = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
	};

	render() {
		const {isOpen} = this.state;
		const {title, children, iconName} = this.props;
		const titleClassName = classnames('collapse-div__title', {open: isOpen});
		return (
			<div className="collapse-div">
				<div className={titleClassName} onClick={this.toggleIsOpen}>
					{iconName && (
						<span className="icon">
							<FontAwesomeIcon icon={iconName} />
						</span>
					)}
					{title}
					<span className="arrow-down">
						<FontAwesomeIcon icon={faAngleDown}/>
					</span>
				</div>
				<Collapse isOpened={isOpen}>
					<div className="collapse-div__children">{children}</div>
				</Collapse>
			</div>
		);
	}
}

export default CollapseDiv;
