import React from 'react';
import {Input, Button, DateField, Select} from './../basics/components';
import {isEmpty} from '../utils/utils';
import Header from '../components/header';
import {connect} from 'react-redux';
import {post} from '../services/service';
import PasswordForm from './passwordForm';
import {getDateNYearsInPast} from '../utils/appUtils';
import RadioGroup from '../basics/radio/radioGroup';
import {formatToIsoString} from '../utils/dateUtils';
import { faSuitcase, faHome, faTruck, faEnvelope, faMobile, faCalendar, faUser, faUserCircle, faVenusMars } from '@fortawesome/free-solid-svg-icons';

const mapStateToProps = ({cityData}) => {
	return {
		cities: cityData.cities.map(({id, naziv, zipcode}) => ({value: id, label: naziv + ' ' + zipcode}))
	};
};

export const TELEPHONE_PREFIX = '+381 ';
const FRIEND_PARAM = 'friend';

class RegistrationPage extends React.Component {
	state = {
		request: {
			username: '',
			name: '',
			email: '',
			surname: '',
			telephone: '',
			birthday: '',
			addressNumber: '',
			naseljeId: '',
			street: '',
			address: ''
		},
		formIsValid: false,
		hideAddressNumber: false
	};

	fieldRefs = [];

	changeRequestProperty = (id, value) => {
		this.setState(
			{
				request: {
					...this.state.request,
					[id]: value
				}
			},
			this.checkValidation
		);
	};

	handleChange = (e) => {
		this.changeRequestProperty(e.target.id, e.target.value);
	};

	handleTelephoneChange = (e) => {
		const value = String(e.target.value).substring(TELEPHONE_PREFIX.length);
		if (!isNaN(value)) {
			this.changeRequestProperty(e.target.id, value);
		}
	};

	handleDateChange = (value) => {
		this.changeRequestProperty('birthday', value);
	};

	handleGenderChange = (questionOrder, {text}, oldvDalue, id) => {
		this.changeRequestProperty(id, text);
	};

	checkValidation = () => {
		this.setState({
			formIsValid: this.isFormValid()
		});
	};

	isFormValid = () => {
		return this.fieldRefs
			.filter((f) => !!f)
			.map((f) => f.checkValidation().isValid)
			.every((f) => !!f);
	};

	register = () => {
		const request = this.getTransformedRequest();
		this.isFormValid() && post('/user/CheckNewUser', request, this.successRegistration);
	};

	successRegistration = ({id}) => {
		this.setState({
			showPasswordForm: true,
			userId: id
		});
	};

	getUsernameDepend = () => {
		const url = new URL(window.location.href);
		const friendUsername = url.searchParams.get(FRIEND_PARAM);
		return friendUsername;
	};

	getTransformedRequest = () => {
		const {username, name, surname, email, telephone, naseljeId, addressNumber, street, gender} = this.state.request;
		const usernameDepend = this.getUsernameDepend() || '';
		return {
			user: {
				name: name,
				surname: surname,
				birthday: this.getBirthday(),
				email: email,
				telephone: telephone,
				naseljeId: naseljeId,
				streetNo: `${street}${addressNumber ? '/' + addressNumber : ''}`,
				Pol: gender
			},
			userName: username,
			userNameDepend: usernameDepend
		};
	};

	onSelectChange = (selectedOption) => {
		this.setState(
			{
				request: {
					...this.state.request,
					naseljeId: selectedOption.value
				}
			},
			this.checkValidation
		);
	};

	getBirthday = () => {
		const {birthday} = this.state.request;
		return !isEmpty(birthday) && formatToIsoString(birthday);
	};

	checkEmail = () => {
		const {email} = this.state.request;
		const emailRegex = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRegex.test(email)) {
			return {isValid: true};
		}
		return {isValid: false, message: 'Netacna email adresa'};
	};

	checkNumber = () => {
		const {telephone} = this.state.request;
		const numberRegex = RegExp('^[+]?[(]?[0-9]{1,4}[)]?[-s./0-9]*$', 'g');
		if (numberRegex.test(telephone) && telephone.length > 5) {
			return {isValid: true};
		}
		return {isValid: false, message: telephone.length < 6 ? 'Minimum 6 karaktera je potrebno' : 'Broj nije ispravan'};
	};

	hideAddressNumber = () => {
		const {street} = this.state.request;
		const isStreetEmpty = isEmpty(street);
		if (isStreetEmpty) {
			if (this.state.request['addressNumber'] !== '') {
				this.changeRequestProperty('addressNumber', '');
			}
		}
		this.setState({
			hideAddressNumber: !isStreetEmpty
		});
	};

	render() {
		const {hideAddressNumber, request, formIsValid, showPasswordForm, userId} = this.state;
		const {username, name, email, surname, telephone, street, addressNumber, birthday} = request;
		let i = 0;
		return (
			<div className="registration-container">
				<Header />
				<div className="registration-form">
					<div className="title registration-title">NAPRAVITE NALOG</div>
					<div className="form">
						{showPasswordForm ? (
							<PasswordForm username={username} userId={userId} regRequest={this.getTransformedRequest()} />
						) : (
							<>
								<RadioGroup
									id="gender"
									className="registration-radio"
									onChange={this.handleGenderChange}
									ref={(ref) => (this.fieldRefs[i++] = ref)}
									small={true}
									useSelectedIndexFromProps={false}
									iconName={faVenusMars}
									items={[{text: 'Muski'}, {text: 'Zenski'}]}
								/>
								<Input
									ref={(ref) => (this.fieldRefs[i++] = ref)}
									id="username"
									placeholder="Korisnicko Ime"
									iconName={faUserCircle}
									value={username}
									receiveProps={false}
									onChange={this.handleChange}
								/>
								<Input
									ref={(ref) => (this.fieldRefs[i++] = ref)}
									id="name"
									placeholder="Ime"
									value={name}
									iconName={faUser}
									receiveProps={false}
									onChange={this.handleChange}
								/>
								<Input
									ref={(ref) => (this.fieldRefs[i++] = ref)}
									id="surname"
									placeholder="Prezime"
									value={surname}
									iconName={faUser}
									receiveProps={false}
									onChange={this.handleChange}
								/>
								<DateField
									ref={(ref) => (this.fieldRefs[i++] = ref)}
									id="birthday"
									placeholder="Datum rodjenja"
									value={birthday}
									iconName={faCalendar}
									onChange={this.handleDateChange}
									receiveProps={false}
									maxDate={getDateNYearsInPast(16)}
								/>
								<Input
									ref={(ref) => (this.fieldRefs[i++] = ref)}
									id="telephone"
									placeholder="Broj telefona"
									checkValidation={this.checkNumber}
									value={TELEPHONE_PREFIX + telephone}
									iconName={faMobile}
									receiveProps={false}
									onChange={this.handleTelephoneChange}
								/>
								<Input
									ref={(ref) => (this.fieldRefs[i++] = ref)}
									id="email"
									placeholder="Email adresa"
									value={email}
									checkValidation={this.checkEmail}
									iconName={faEnvelope}
									receiveProps={false}
									onChange={this.handleChange}
								/>
								<span className="address-span">Adresa</span>
								<div className="sub-form">
									<Input
										ref={(ref) => (this.fieldRefs[i++] = ref)}
										id="street"
										placeholder="Ulica i Broj"
										onBlur={this.hideAddressNumber}
										value={street}
										iconName={faTruck}
										receiveProps={false}
										onChange={this.handleChange}
									/>
									<Input
										ref={(ref) => (this.fieldRefs[i++] = ref)}
										hidden={!hideAddressNumber}
										id="addressNumber"
										placeholder="Broj stana"
										value={addressNumber}
										required={false}
										iconName={faSuitcase}
										receiveProps={false}
										onChange={this.handleChange}
									/>
									<Select
										id="naseljeId"
										ref={(ref) => (this.fieldRefs[i++] = ref)}
										iconName={faHome}
										placeholder="Naselje"
										noOptionsMessage="Nema rezultata"
										options={this.props.cities}
										onChange={this.onSelectChange}
									/>
								</div>
							</>
						)}
					</div>
					{!showPasswordForm && <Button isDisabled={!formIsValid} label="REGISTRUJ SE" onClick={this.register} />}
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(RegistrationPage);
