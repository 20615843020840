import React from 'react';
import {Button, Input} from '../basics/components';
import {post} from '../services/service';
import HandleEnterClick from '../utils/handleEnterClick';
import {setCredentials, setUserId, setToken} from '../../redux/actions/userActions';
import store from '../../redux/store';
import PropTypes from 'prop-types';
import MessageComponent from '../basics/messageComponent';
import {redirectTo, saveUserData} from '../utils/appUtils';
import Routes from '../../utils/routes';
import NavLinkButton from '../basics/NavLinkButton';
import {closePopup, openPopup} from '../../redux/actions/modalActions';
import {COOCKIE_USER_DATA, setCookie} from '../utils/coockieUtils';
import ForgotPasswordForm from "./forgotPasswordForm";

class LoginForm extends HandleEnterClick {
	static contextTypes = {
		router: PropTypes.shape({
			history: PropTypes.shape({
				push: PropTypes.func.isRequired
			})
		})
	};

	state = {
		username: '',
		password: '',
		saveMe: false,
		showError: false,
	};

	onEnterClick = () => {
		const redirectUrl = this.props.redirectUrl || Routes.PROFILE;
		this.login(() => {
			redirectTo(redirectUrl);
		});
	};

	login = (redirectCallback) => {
		const {username, password} = this.state;
		post('/login/getUserNew', {username, password}, (response) => this.successLogin(response, redirectCallback), this.errorOnLogin);
	};

	successLogin = (response, redirectCallback) => {
		const {saveMe} = this.state;
		const userId = response['DidiApi.Models.User'].Id;
		const token = response.token;
		const userData = {
			userId: userId, 
			token: token
		};
		if (saveMe) {
			setCookie(COOCKIE_USER_DATA, JSON.stringify(userData));

		}
		saveUserData(userData);
		store.dispatch(setUserId(userId));
		store.dispatch(setToken(token));
		store.dispatch(
			setCredentials({
				userId: userId,
				username: this.state.username,
				password: this.state.password
			})
		);
		store.dispatch(closePopup());
		redirectCallback();
	};

	errorOnLogin = () => {
		this.setState({
			showError: true
		});
	};

	setPassword = (e) => {
		this.setState({password: e.target.value});
	};

	setUsername = (e) => {
		this.setState({username: e.target.value});
	};

	toggleSaveMe = () => {
		this.setState({saveMe: !this.state.saveMe});
	};

	openForgotPasswordForm = () => {
		store.dispatch(
			openPopup(
				<>
					<ForgotPasswordForm />
				</>
			)
		);
	};

	render() {
		const {username, password, saveMe, showError} = this.state;
		return (
			 <div className="login-form">
				<div className="login-title">Login</div>
				{showError && <MessageComponent message="Pogrešno korisničko ime ili šifra." />}
				<div className="form">
					<Input label="Korisničko ime ili e-mail:" value={username} onChange={this.setUsername} onKeyPress={this.onKeyPress} />
					<Input label="Lozinka:" value={password} onChange={this.setPassword} type="password" onKeyPress={this.onKeyPress} />
					<Input label="Zapamti me" value={saveMe} onChange={this.toggleSaveMe} type="checkbox" required={false} />
					<div className="buttons-pair">
						<NavLinkButton label="ULOGUJ SE" to={Routes.PROFILE} beforeChange={this.onEnterClick} className="btn" />
						<Button className="second" label="Zaboravljena lozinka" onClick={this.openForgotPasswordForm}/>
					</div>
				</div>
			 </div>
		);
	}
}

export default LoginForm;