import {getCookie, COOCKIE_USER_DATA} from './coockieUtils';
import {isEmpty} from './utils';
import {IMAGE_ANSWERS, IMAGE_QUESTION_ID} from "../components/profile/profilePoll";

const USER_DATA = 'USER_DATA';

export const redirectTo = (page) => {
	window.location = page;
};

export const saveUserData = (userData) => {
	try {
		localStorage.setItem(USER_DATA, JSON.stringify(userData));
	} catch {
		removeUserData();
	}
};

export const removeUserData = () => {
	localStorage.setItem(USER_DATA, JSON.stringify({}));
};

export const calculateAnsweredCategories = (answers) => {
	const isAnswered = (id) => !!answers.find(a => id === a.answerId);
	const categories = {};
	IMAGE_ANSWERS.forEach(answerId => {
		categories[answerId] = isAnswered(answerId);
	});
	return categories;
};

export const getKidQuestion = () => ({
	"id": 99999999,
	"text": "Koliko dece mlađe od 12 godina živi sa tobom?",
	"kidQuestion": true
});

export const filterQuestions = (questions, categories) => {
	if (hideFirstQuestions(categories)) {
		return questions.filter(q => q.id === IMAGE_QUESTION_ID);
	}
	return questions;
};

export const hideFirstQuestions = (categories) => {
	return categories && Object.values(categories).some(c => c === true);
};

export const getUserData = () => {
	const localUserData = getLocalUserData();
	const rememberdUserData = getCookie(COOCKIE_USER_DATA);
	if (localUserData && localUserData.userId) {
		return localUserData;
	} else if (!isEmpty(rememberdUserData)) {
		saveUserData(rememberdUserData);
		return rememberdUserData;
	}
	return {};
};

const getLocalUserData = () => {
	try {
		return JSON.parse(localStorage.getItem(USER_DATA));
	} catch {
		return {};
	}
};

export const getUserId = () => {
	const userData = getLocalUserData();
	return userData && userData.userId;
};

export const getDateNYearsInPast = (years) => {
	const date = new Date();
	date.setFullYear(date.getFullYear() - years);
	return date;
};

export const toNextYear = (date) => {
	const d = new Date(date);
	d.setFullYear(d.getFullYear() + 1);
	d.setMonth(0);
	d.setDate(1);
	return d;
};