import {useEffect} from 'react';

const useOutsideClick = (ref, method) => {
	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			method();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	});
};

export default useOutsideClick;
