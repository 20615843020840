import React, {Component} from 'react';
import {setUserProfileData} from '../../../redux/actions/userActions';
import {post} from '../../services/service';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ProfileTab from './profileTab';
import Header from '../../components/header';
import {isEmpty} from '../../utils/utils';
import {redirectTo, getUserData} from '../../utils/appUtils';
import Routes from '../../../utils/routes';
import {showLoader} from '../../../redux/actions/loaderActions';
import BoxesTab from '../box/boxesTab';
import { withRouter } from "react-router";

const mapStateToProps = ({userData}) => {
	return {profileData: userData.profileData, userId: userData.userId};
};

class ProfilePage extends Component {
	constructor(props) {
		super(props);
		const {userId, token} = getUserData();
		if (isEmpty(userId) || isEmpty(token)) {
			redirectTo(Routes.LOGIN + props.location.pathname);
		} else {
			this.getData(userId, token);
		}
	}

	getData = (userId, token) => {
		const {dispatch} = this.props;
		const request = {
			UserId: userId,
			token: token	
		};
		dispatch(showLoader());
		post('/user/Korisnik', request, (data) => dispatch(setUserProfileData(data['DidiApi.Models.User'], data.userName)));
	};

	render() {
		const {profileData} = this.props;
		const defaultIndex = window.location.href.endsWith('profile/PB') ? 1 : 0;
		return (
			<div className="profile-container">
				<Header />
				<Tabs defaultIndex={defaultIndex}>
					<TabList>
						<Tab>Vaš profil</Tab>
						<Tab>Moje PB Kutije</Tab>
					</TabList>
					<TabPanel>
						<ProfileTab profileData={profileData} />
					</TabPanel>
					<TabPanel>
						<BoxesTab userId={(profileData && profileData.userId) || null} />
					</TabPanel>
				</Tabs>
			</div>
		);
	}
}

export default compose(withRouter, connect(mapStateToProps))(ProfilePage);
