import React from 'react';
import store from '../../redux/store';
import {openPopup} from '../../redux/actions/modalActions';

const ErrorPopup = ({message}) => {
	return <div className="error-popup__message">{message}</div>;
};

export const createErrorPopup = (message) => {
	message && store.dispatch(openPopup(<ErrorPopup message={message} />, 'error-popup'));
};
