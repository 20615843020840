import React from 'react';
import Dropzone from 'react-dropzone';
import {isEmpty} from '../../utils/utils';
import * as classnames from 'classnames';

export default class ImageInput extends React.Component {
	state = {
		file: null,
		validationChecked: false,
		isValid: false
	};

	onSelected = (files) => {
		const {onChange} = this.props;
		const file = files[0];
		const fileObject = {
			name: file.name,
			src: URL.createObjectURL(file),
			byte: this.getFileByte(file),
			extension: this.getExtension(file)
		};
		this.setState(
			{
				file: fileObject,
				validationChecked: true,
				isValid: true
			},
			() => onChange && onChange(fileObject)
		);
	};

	getFileByte = (file) => {
		const reader = new FileReader();
		const fileByteArray = [];
		reader.readAsArrayBuffer(file);
		reader.onloadend = function(evt) {
			if (evt.target.readyState === FileReader.DONE) {
				const arrayBuffer = evt.target.result,
					array = new Uint8Array(arrayBuffer);
				for (let i = 0; i < array.length; i++) {
					fileByteArray.push(array[i]);
				}
			}
		};
		return fileByteArray;
	};

	handleOnBlur = () => {
		const validationObject = this.checkValidation();
		this.setState({
			isValid: validationObject.isValid,
			errorMessage: validationObject.message,
			validationChecked: true
		});
	};

	checkValidation = () => {
		if (this.props.required && isEmpty(this.state.file)) {
			return {
				isValid: false,
				message: 'Ovo polje je obavezno'
			};
		}
		return this.props.checkValidation ? this.props.checkValidation() : {isValid: true};
	};

	getExtension = (file) => {
		const dotIndex = file.name.lastIndexOf('.');
		return file.name.substring(dotIndex);
	};

	render() {
		const {file, isValid, errorMessage, validationChecked} = this.state;
		const {label} = this.props;
		const dropBoxClassName = classnames('drop-box', {error: !isValid && validationChecked});
		return (
			<div className="image-input">
				<Dropzone onDrop={this.onSelected} accept="image/*">
					{({getRootProps, getInputProps}) => (
						<div {...getRootProps()} className={dropBoxClassName}>
							<input {...getInputProps()} />
							{!!file ? <img src={file.src} alt={file.name} /> : label}
						</div>
					)}
				</Dropzone>
				{!isValid && validationChecked && <span className="error-message">{errorMessage}</span>}
			</div>
		);
	}
}

ImageInput.defaultProps = {
	label: 'Dodajte ili prevucite fajl...',
	required: true
};
