import React from "react";
import ProfilePoll from "../../components/profile/profilePoll";
import {connect} from "react-redux";

const mapStateToProps = ({userData}) => {
	return {
		profileData: userData.profileData,
	};
};

class ProfileTab extends React.Component {
	render() {
		return (
			<div className="profile-content">
				{this.props.profileData && (
					<ProfilePoll />
				)}
			</div>
		);
	}
}

export default connect(mapStateToProps)(ProfileTab);
